<template>
  <form @submit.prevent="handleSubmit">
    <div class="container-sm px-3">
      <Box>
        <label for="assetOrSymbolA" class="d-block">Token A</label>
        <input
          id="assetOrSymbolA"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="Symbol or base64 asset ID"
          v-model="assetOrSymbolA"
        />
        <div v-if="symbolA" class="mt-2">
          <label>Symbol</label>
          <p v-text="symbolA" class="text-white" />
          <label>Asset</label>
          <p v-text="assetA" class="text-white" />
          <label>Decimals</label>
          <p v-text="decimalsA" class="text-white" />
        </div>
      </Box>
      <Box>
        <label for="assetOrSymbolB" class="d-block">Token B</label>
        <input
          id="assetOrSymbolB"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="Symbol or base64 asset ID"
          v-model="assetOrSymbolB"
        />
        <div v-if="symbolB" class="mt-2">
          <label>Symbol</label>
          <p v-text="symbolB" class="text-white" />
          <label>Asset</label>
          <p v-text="assetB" class="text-white" />
          <label>Decimals</label>
          <p v-text="decimalsB" class="text-white" />
        </div>
      </Box>
      <Box>
        <label for="swapFee" class="label-padding">Swap fee (%)</label>
        <Tooltip
          style="display: inline-block"
          tooltip-description="Swapping fee, percentage of traded amount."
        />
        <input
          id="swapFee"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="swapFee"
          type="number"
          step="0.0001"
          min="0.0001"
          max="10"
        />
      </Box>
      <Box>
        <label for="exitFee" class="label-padding">Exit fee (%)</label>
        <Tooltip
          style="display: inline-block"
          tooltip-description="Fee charged when removing liquidity from the pool."
        />
        <input
          id="exitFee"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="exitFee"
          type="number"
          step="0.01"
          min="0"
          max="90"
        />
      </Box>
      <Box>
        <label for="arbProfitTax" class="label-padding">Arbitrageur profit tax (%)</label>
        <Tooltip
          style="display: inline-block"
          tooltip-description="Additional fee that is charged as a percentage of arbitrageur profit. It is assumed that arbitrageurs buy from the pool in order to sell elsewhere and make a profit from the difference in prices."
        />
        <input
          id="arbProfitTax"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="arbProfitTax"
          type="number"
          step="0.1"
          min="0"
          max="200"
        />
      </Box>
      <Box>
        <label for="leverageProfitTax" class="label-padding"
          >Tax on profit from leveraged positions (%)</label
        >
        <Tooltip
          style="display: inline-block"
          tooltip-description="Percentage of profit charged from a leveraged position when it is closed (if the close price is higher than the open price)."
        />
        <input
          id="leverageProfitTax"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="leverageProfitTax"
          type="number"
          step="0.1"
          min="0"
          max="90"
        />
      </Box>
      <Box>
        <label for="leverageTokenTax" class="label-padding"
          >Tax on selling leveraged tokens (%)</label
        >
        <Tooltip
          style="display: inline-block"
          tooltip-description="Percentage of the redeemed amount charged when redeeming a leveraged token."
        />
        <input
          id="leverageTokenTax"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="leverageTokenTax"
          type="number"
          step="0.1"
          min="0"
          max="90"
        />
      </Box>
      <Box>
        <label for="baseInterestRate" class="label-padding"
          >Base interest rate on borrowed funds used for leverage (%)</label
        >
        <Tooltip
          style="display: inline-block"
          tooltip-description="Base interest rate charged from leveraged positions. If there are many leveraged positions, the rate can increase depending on utilization."
        />
        <input
          id="baseInterestRate"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="baseInterestRate"
          type="number"
          step="0.1"
          min="0"
          max="200"
        />
      </Box>
      <Box>
        <label for="midPrice" class="label-padding">Mid-price for stable pairs</label>
        <Tooltip
          style="display: inline-block"
          tooltip-description="Mid-price for stablecoin pools."
        />
        <input
          id="midPrice"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="midPrice"
          type="number"
          min="0"
          step="0.00000001"
        />
      </Box>
      <Box>
        <label for="priceDeviation" class="label-padding">Price deviation for stable pairs</label>
        <Tooltip
          style="display: inline-block"
          tooltip-description="This factor indicates how far the price is allowed to deviate from the mid-price."
        />
        <input
          id="priceDeviation"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="priceDeviation"
          type="number"
          min="0"
          step="0.00000001"
        />
        <div v-if="symbolA && symbolB">
          Price range from {{ p_min }} to {{ p_max }} {{ symbolB }} for 1 {{ symbolA }}
        </div>
      </Box>
      <Box>
        <label for="poolLeverage" class="label-padding">Pool leverage</label>
        <Tooltip
          style="display: inline-block"
          tooltip-description="A multiplier that makes the pool behave like it has more liquidity than it really has. The full multiplier is applied when the pool is balanced and it decreases as the pool goes out of balance."
        />
        <input
          id="poolLeverage"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="poolLeverage"
          type="number"
          min="1"
          max="100"
          step="0.1"
        />
      </Box>
      <Box>
        <label for="alpha" class="d-block">Weight of the first token</label>
        <input
          id="alpha"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="alpha"
          type="number"
          min="0.05"
          max="0.95"
          step="0.01"
        />
      </Box>
      <Box>
        <label for="periodLength" class="d-block"
          >Period to track min and max prices for one-sided liquidity adds/removals</label
        >
        <input
          id="periodLength"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="periodLength"
          type="number"
          min="0"
          max="86400"
          step="1"
        />
      </Box>
      <Box>
        <label for="shares_bonding_curve" class="d-block"
          >Bonding curve for shares (the default is linear)</label
        >
        <input
          id="shares_bonding_curve"
          class="form-control input-amount border-0 p-0"
          autocomplete="off"
          placeholder="0.0000"
          v-model="shares_bonding_curve"
          type="text"
        />
      </Box>
      <div class="text-center mb-4">
        <button
          class="btn-submit px-6 rounded-2 mb-3"
          type="submit"
          :disabled="
            !assetA || !assetB || +poolLeverage === 1 / alpha || +poolLeverage === 1 / (1 - alpha)
          "
        >
          Create a pool
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { b64UriDec } from '@/helpers/utils';
import { generateUri, FACTORY_ADDRESSES } from '@/helpers/_oswap';
import Tooltip from '@/components/Tooltip.vue';

const linear_bonding_curve = 'IXBHF6T4IKMYAFGRM54F5FVMXGKCTFNT';

export default {
  components: { Tooltip },
  data() {
    return {
      assetOrSymbolA: 'GBYTE',
      assetOrSymbolB: '',
      assetA: 'base',
      assetB: '',
      swapFee: '0.1',
      exitFee: '1.0',
      arbProfitTax: '90.0',
      leverageProfitTax: '25.0',
      leverageTokenTax: '10.0',
      baseInterestRate: '10.0',
      midPrice: '0',
      priceDeviation: '0',
      poolLeverage: 1,
      alpha: 0.5,
      periodLength: '3600',
      linear_bonding_curve: linear_bonding_curve,
      shares_bonding_curve: linear_bonding_curve,
      symbolA: 'GBYTE',
      symbolB: false,
      decimalsA: 9,
      decimalsB: 0
    };
  },
  watch: {
    assetOrSymbolA(value, oldValue) {
      if (value !== oldValue) {
        this.assetA = false;
        this.symbolA = false;
        this.decimalsA = 0;
        if (value.length === 44 || value === 'base') {
          this.assetA = value;
          if (this.assetToSymbol[value]) {
            this.symbolA = this.assetToSymbol[value];
            this.decimalsA = this.decimals[value] || 0;
          }
        } else if (this.symbolToAsset[value]) {
          const asset = this.symbolToAsset[value];
          this.assetA = asset;
          this.symbolA = value;
          this.decimalsA = this.decimals[asset] || 0;
        }
      }
    },
    assetOrSymbolB(value, oldValue) {
      if (value !== oldValue) {
        this.assetB = false;
        this.symbolB = false;
        this.decimalsB = 0;
        if (value.length === 44 || value === 'base') {
          this.assetB = value;
          if (this.assetToSymbol[value]) {
            this.symbolB = this.assetToSymbol[value];
            this.decimalsB = this.decimals[value] || 0;
          }
        } else if (this.symbolToAsset[value]) {
          const asset = this.symbolToAsset[value];
          this.assetB = asset;
          this.symbolB = value;
          this.decimalsB = this.decimals[asset] || 0;
        }
      }
    }
  },
  computed: {
    assetToSymbol() {
      return this.settings.assetToSymbol;
    },
    symbolToAsset() {
      return this.settings.symbolToAsset;
    },
    decimals() {
      return this.settings.decimals;
    },
    priceMultiplier() {
      return 10 ** (this.decimalsA - this.decimalsB);
    },
    p_max() {
      if (!+this.midPrice || !+this.priceDeviation) return Infinity;
      const beta = 1 - this.alpha;
      return ((this.alpha / beta) * this.priceDeviation ** (1 / beta) * this.midPrice).toPrecision(
        6
      );
    },
    p_min() {
      if (!+this.midPrice || !+this.priceDeviation) return 0;
      const beta = 1 - this.alpha;
      return ((this.alpha / beta / this.priceDeviation ** (1 / beta)) * this.midPrice).toPrecision(
        6
      );
    }
  },
  created() {
    const assetB = this.$route.params[0] || this.$route.params.pathMatch || '';
    if (assetB) this.assetB = b64UriDec(assetB);
  },
  methods: {
    handleSubmit() {
      const data = {
        x_asset: this.assetA,
        y_asset: this.assetB,
        swap_fee: this.swapFee / 100,
        exit_fee: this.exitFee / 100,
        arb_profit_tax: this.arbProfitTax / 100,
        leverage_profit_tax: this.leverageProfitTax / 100,
        leverage_token_tax: this.leverageTokenTax / 100,
        base_interest_rate: this.baseInterestRate / 100,
        ...(+this.midPrice && { mid_price: +this.midPrice / this.priceMultiplier }), // from display units to pennies
        ...(+this.priceDeviation && { price_deviation: +this.priceDeviation }),
        pool_leverage: +this.poolLeverage,
        alpha: +this.alpha,
        period_length: +this.periodLength,
        ...(this.shares_bonding_curve &&
          this.shares_bonding_curve !== linear_bonding_curve && {
            shares_bonding_curve: this.shares_bonding_curve
          })
      };
      const url = generateUri(FACTORY_ADDRESSES[0], data);
      if (navigator.userAgent.indexOf('Firefox') != -1) {
        const opener = window.open(url, '', 'width=1,height=1,resizable=no');
        setTimeout(function() {
          opener.close();
        }, 5000);
      } else {
        location.href = url;
      }
    }
  }
};
</script>
